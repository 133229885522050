import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from "gatsby"

import './simplegrid.css'
import './layout.css'

const ListLink = props =>
  <li style={{ display: `inline-block`, marginRight: `0.5rem` }}>
    <Link to={props.to}>
      {props.children}
    </Link>
  </li>

const Layout = ({ children }) => (
  <div style={{ margin: `0 auto`, maxWidth: 850, padding: `1.25rem 1rem` }}>

    <Helmet
      title="Ollie Glass - data science, machine learning &amp; data strategy"
    >
      {/* General tags */}
      <meta name="description" content="Commercial machine learning systems and strategies for fast growing companies." />
      {/*<meta name="image" content={image} />*/}

      <meta name="google-site-verification" content="rvZZQM9_PSENuyEeo4gS4Iu972qssTQnifk73ePR06o" />
      <script type="text/javascript">{`
          window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
          c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
          })(document);
          smartlook('init', '22317595d614e19e1a73959d93c5ea3db08fdc0b');
      `}</script>

      <html lang="en" />
    </Helmet>

    <header style={{ marginBottom: '1rem' }}>
      <Link to="/" style={{ textDecoration: 'none', color: '#345678' }}>
        <h1 style={{ display: 'inline' }}>Ollie Glass</h1>
      </Link>

      <ul style={{
        listStyle: 'none',
        paddingLeft: 0,
        marginLeft: 0,
        marginBottom: 0,
        marginTop: 10
      }}>
        <ListLink to="/">Home</ListLink>
        <ListLink to="/services/">Services</ListLink>
        <ListLink to="/case-studies/">Case studies</ListLink>
        <ListLink to="/articles/">Articles</ListLink>
      </ul>
    </header>

    {children}

  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
